<template lang="html">
  <section :class="`main-view ${mainViewExpand&&'expand'}`" :style="globalCssVar">
    <div class="container-scroller">
      <app-header :isMobile="isMobile" :themeConfig="themeConfig" />
      <div class="container-fluid page-body-wrapper">
        <b-sidebar
          v-if="isMobile"
          class="app-sidebar"
          id="sidebar-backdrop"
          backdrop
          shadow
          width="270px"
        >
          <app-sidebar @updateView="updateView" :themeConfig="themeConfig" />
        </b-sidebar>
        <app-sidebar v-else @updateView="updateView" :themeConfig="themeConfig" />
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view :key="$route.fullPath"></router-view>
          </div>
          <app-footer v-if="footerText" :footer-text="footerText" />
        </div>
      </div>
    </div>
    <script type="application/javascript">{{dashboardScript}}</script>
  </section>
</template>

<script lang="js">
import { mapState } from "vuex";
import AppHeader from '@/components/partials/AppHeader'
import AppSidebar from '@/components/partials/AppSidebar'
import AppFooter from '@/components/partials/AppFooter'

export default {
  components: {
    AppHeader,
    AppSidebar,
    AppFooter
  },
  data () {
    return {
      viewKey: Date.now(),
      isMobile: false
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      organizationThemeConfig: state => state.organizationThemeConfig,
      mainViewExpand:state=>state.mainViewExpand
    }),
    themeConfig() {
      return this.organizationThemeConfig?.dashboard ??  {
        sidebar_bg_color: '#636363',
        sidebar_menu_color: "#8798ad",
        sidebar_menu_active_bg_color: "#fafbfc",
        sidebar_menu_active_color: "#8798ad",
        og_title: null,
        og_favicon_url: null,
      }
    },
    dashboardScript() {
      if (this.organization.dashboard_script_variable) {
        return this.organization.dashboard_script_variable.value.trim().replace(/^<script>/i, '').replace(/<\/script>$/, '')
      }
      return null
    },
    globalCssVar() {
      return {
        "--sidebar-bg-color": this.themeConfig.sidebar_bg_color,
        "--sidebar_menu_color": this.themeConfig.sidebar_menu_color,
        "--sidebar_menu_active_bg_color": this.themeConfig.sidebar_menu_active_bg_color,
        "--sidebar_menu_active_color": this.themeConfig.sidebar_menu_active_color
      };
    },
    footerText() {
      return this.organizationThemeConfig?.dashboard?.footer_text
    },
  },
  created() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    document.body.classList.add('admin-panel')
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    updateView () {
      this.viewKey = Date.now()
    },
    onResize() {
      this.isMobile = window.innerWidth < 768
    }
  }
}
</script>

<style lang="scss">
@import "../../../node_modules/mdi/css/materialdesignicons.min.css";
@import "../../../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../../../node_modules/font-awesome/css/font-awesome.min.css";
@import "../../../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../../../node_modules/ti-icons/css/themify-icons.css";
@import "../../../node_modules/ionicons/dist/css/ionicons.min.css";
@import "../../../node_modules/codemirror/lib/codemirror.css";
@import "../../../node_modules/vue-snotify/styles/material.css";
@import "../../../node_modules/typicons.font/src/font/typicons.css";

/* override b-sidebar background color in mobile  */
.b-sidebar-header,
.b-sidebar-body,
.sidebar {
  background: var(--sidebar-bg-color);
}

.preserve-spacing-for-datetime-picker {
  // NOTE: date picker & time picker 的選單會超出畫面下緣，因此目前先以增加 margin 的方式快速修正
  // 若要從根本解決的話可以研究 date picker & time picker 是否有提供參數讓選擇的介面根據螢幕大小自動調整選擇介面的位置
  margin-bottom: 240px;
}
</style>
