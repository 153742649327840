<template lang="html">
  <b-navbar
    id="template-header"
    class="navbar default-layout col-12 p-0 fixed-top d-flex flex-row"
    toggleable="md"
  >
    <div
      class="
        text-center
        navbar-brand-wrapper
        d-flex
        align-items-center
        justify-content-center
        flex-grow-1 flex-xl-grow-0
      "
    >
      <div
        v-if="isMobile"
        v-b-toggle.sidebar-backdrop
        class="d-flex align-items-center ml-2 mr-4"
      >
        <BIconList class="h4 mb-0" />
      </div>
      <div
        v-else
        role="button"
        class="d-flex align-items-center mr-2"
        @click="setMainViewExpand(!mainViewExpand)"
      >
        <BIconList class="h4 mb-0" />
      </div>
      <router-link
        class="navbar-brand brand-logo"
        :to="{ name: 'DashboardHome' }"
      >
        <h5 class="font-bold m-0" style="line-height: 63px">
          {{ this.organization.name }}
        </h5>
      </router-link>
      <router-link
        class="navbar-brand brand-logo-mini "
        :to="{ name: 'DashboardHome' }"
      >
        <h5 class="font-bold m-0" style="line-height: 63px">
          {{ this.organization.name }}
        </h5>
      </router-link>
    </div>
    <div
      class="navbar-menu-wrapper d-flex align-items-center ml-lg-0 flex-grow-1"
    >
      <b-navbar-nav class="navbar-nav-left ml-auto">
        <form class="mr-auto search-form d-none d-md-block" action="#"></form>

<!--        <b-dropdown id="dropdown-1" text="切換樣式" class="sidebar-theme m-md-2">-->
<!--          <b-dropdown-item-->
<!--            v-for="({ name, id }) in sidebarThemes"-->
<!--            :key="id"-->
<!--            :active="id === sidebarTheme"-->
<!--            @click="setSidebarTheme(id)"-->
<!--            >{{ name }}</b-dropdown-item-->
<!--          >-->
<!--        </b-dropdown>-->

        <li
          v-if="$permissions.has($permissions.consts.MY_NOTIFICATION)"
          class="nav-item dropdown"
        >
          <router-link
            class="nav-link count-indicator"
            :to="{ name: 'BranchNotificationList' }"
          >
            <div class="d-flex">
              <i class="mdi mdi-bell-outline"></i>
              <sup v-if="unreadNotificationCount > 0">
                <b-badge pill variant="danger">{{
                  unreadNotificationCount
                }}</b-badge>
              </sup>
            </div>
          </router-link>
        </li>
        <b-nav-item-dropdown right class="preview-list user-dropdown">
          <template slot="button-content">
            <b-avatar :src="staff.avatar_url" size="2rem"></b-avatar>
          </template>
          <b-dropdown-item>
            <div class="dropdown-header text-center w-100 mx-0 px-0">
              <b-avatar :src="staff.avatar_url" size="4rem"></b-avatar>
              <p v-if="staff.name" class="mb-1 mt-3 font-weight-semibold max-w-full overflow-hidden">
                {{ staff.name }}
              </p>
              <p v-if="staff.employee_code" class="font-weight-light text-muted mb-0 max-w-full overflow-hidden">
                {{ staff.employee_code }}
              </p>
              <p v-if="staff.email" class="font-weight-light text-muted mb-0 max-w-full overflow-hidden">
                {{ staff.email }}
              </p>
              <p v-if="staff.role_name" class="font-weight-light text-muted mb-0 max-w-full overflow-hidden">
                {{ `身份：${staff.role_name}` }}
              </p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'Profile' }"
          >
            基本資料
            <i class="fa fa-user-circle"></i>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$permissions.has($permissions.consts.MY_STAFF_PROFILE)"
            :to="{ name: 'ExtendedProfile' }"
          >
            個人化設定
            <i class="fa fa-gear"></i>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$permissions.has($permissions.consts.MY_BOOKING_SHIFT)"
            :to="{ name: 'BookingShiftList' }"
          >
            預約班表設定
            <i class="mdi mdi-calendar-clock"></i>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$permissions.has($permissions.consts.MY_CHANGE_PASSWORD)"
            :to="{
              name: 'StaffChangePassword',
              params: { staff_id: this.staff.id },
              query: { profile: 1 },
            }"
          >
            變更密碼
            <i class="fa fa-key"></i>
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            登出
            <i class="fa fa-sign-out"></i>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>

<script>
import { BAvatar, BBadge, BIconList } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

import { SIDEBAR_THEMES } from "@/consts";
import store from "@/store";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: "app-header",
  components: { BAvatar, BBadge, BIconList },
  props: ["isMobile", "themeConfig"],
  data() {
    return {
      SIDEBAR_THEMES,
      sidebarThemes: [
        {
          id: SIDEBAR_THEMES.THEME_1,
          name: '樣式一'
        },
        {
          id: SIDEBAR_THEMES.THEME_2,
          name: '樣式二'
        },
        {
          id: SIDEBAR_THEMES.THEME_3,
          name: '樣式三'
        },
        {
          id: SIDEBAR_THEMES.THEME_4,
          name: '樣式四'
        }
      ]
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
      unreadNotificationCount: (state) => state.unreadNotificationCount,
      mainViewExpand: (state) => state.mainViewExpand,
      sidebarTheme: (state) => state.sidebarTheme,
    }),

    staff: () => {
      return store.state.auth.user;
    },
    avatarUrl: () => {
      return (
        store.state.auth.user.avatar_url ??
        "../../assets/images/default-avatar.png"
      );
    },
  },

  created() {
    if ( this.$permissions.hasAny([this.$permissions.consts.MY_NOTIFICATION, this.$permissions.consts.MY_NOTIFICATION_LATEST]) &&
      this.currentBranch
    ) {
      this.getUnreadNotificationCount();
    }

    this.setSidebarTheme(this.themeConfig.theme_code ?? SIDEBAR_THEMES.THEME_1)
  },

  methods: {
    ...mapActions("general", [
      "getUnreadNotificationCount",
      "setMainViewExpand",
      "setSidebarTheme",
    ]),

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({
          name: "AuthLogin",
          params: { org_code: this.$route.params.org_code },
        });
      });
    },
  },
};
</script>

<style lang="scss">
.navbar.default-layout
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.user-dropdown
  .dropdown-menu {
  position: absolute;
  width: 250px;
  left: 0;
  margin-left: auto;
  margin-right: 1em;
  margin-top: 0;
}

.navbar.default-layout .navbar-brand-wrapper {
  background-color: var(--sidebar-bg-color);
}

.navbar.default-layout .navbar-menu-wrapper {
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    box-shadow: 0 4px 16px 0 rgba(167, 175, 183, 0.33);
  }
  .navbar.default-layout {
    background-color: var(--sidebar-bg-color);
    &.navbar-menu-wrapper {
      box-shadow: none;
    }
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .navbar.default-layout
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item
    .nav-link
    i {
    color: #fff;
  }

  .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
    color: #fff;
  }
}

.sidebar-theme {
  .active {
    background-color: var(--primary);
  }
}
</style>
